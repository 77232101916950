<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <router-link to="/help"
          >-<span v-html="'&nbsp'"></span>帮助<span v-html="'&nbsp'"></span
        ></router-link>
        <a href="javascript:void(0)"
          >-<span v-html="'&nbsp'"></span
          >房屋装修注意事项(菜鸟看后装修无遗憾)--水电安装注意事项</a
        >
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="news_title">
              <div class="newsText">
                房屋装修注意事项(菜鸟看后装修无遗憾)--水电安装注意事项
              </div>
            </div>
            <div class="news_content">
              <p>
                如果你是新房装修、二手房装修，不知道从何着手，如果你在房子装修过程中遇到厨房、卫生间装修不知道需要注意哪些事项，那么小编提供的这篇房屋装修注意事项，会给你不少启发。今天来看下水电安装注意事项。
              </p>
              <br /><br /><br /><br />
              <p class="bold">【电气安装】</p>
              <p class="bold">1、灯具安装</p>
              <p>(1)灯具重量大于3kg,时，固定在螺栓或预埋吊钩上;</p>
              <p>
                (2)软线吊灯，灯具重要在0.5kg及以下时，采用软电线自身吊装;大于0.5kg的灯具采用吊链，且软电线编叉在吊链内，使电线不受力;
              </p>
              <p>
                (3)灯具固定牢固可靠，不使用木楔。每个灯具固定用螺钉或螺栓不少于2个。其余参照《电气装置安装工程电气照明装置施工及验收规范》执行
              </p>
              <br />
              <p class="bold">2、强电开关、插座安装</p>
              <p>(1)横装插座，面对插座的右极接相线，左极接零线，上接地线。</p>
              <p>
                (2)接线：先将盒内甩出的导线留出维修长度(15-20CM)削去绝缘层，注意不要碰伤线
                芯，如开关、插座内为接线柱，将导线按顺时针方向盘绕在开关、插座对应的接线柱上，然后旋紧压头。如开关、插座内为插接端子，将线芯折回头插入圆孔接线端子内(孔经允许压双线时)，再用顶丝将其压紧，注意线芯不得外露。
              </p>
              <p>
                (3)插座的安装高度应符合设计的规定，当设计无规定时，应符合下列要求：暗装用插座距地面不应低于0.3米，特殊场所暗装插座不应小于0.15
                米。在儿童活动场所应采用安全插座。采用普通插座时，其安装高度不应低于1.8
                米。
              </p>
              <p>
                (4)特别关注：为了避免交流电源对电视信号的干扰，电视馈线线管、插座与交流电源线管、插座之间应有0.5m以上的距离(特殊情况下电视信号线采用屏蔽线缆，间距也不得低于0.3m)。
              </p>
              <p>(5)在卫生间厨房等潮湿场所，应采用密封良好的防水防潮插座</p>
              <p>
                (6)相同型号并列安装及同一室内开关安装高度一致，且控制有序不错位。并列安装的插座距离相邻间距不小于20毫米。
              </p>
              <br />
              <p class="bold">3、弱电双绞线568B 标准的接线方法：</p>
              <p>1-白橙、2-橙、3-白绿、4-蓝、5-白蓝、6-绿、7-白棕、8-棕</p>
              <br /><br /><br />
              <p class="bold">【水路安装】</p>
              <p>1、洁具厂家负责安装最好由厂家提供质量良好的软管及阀门</p>
              <p>
                2、坐便器应用膨胀螺丝固定，不得用水泥砂浆固封，底座应与地面平齐，并用油石灰或硅胶连接密封。
              </p>
              <p>
                3、洗手(菜)盆和洗涤槽的排水管应有存水弯，排水管与地面落水口应密封无渗漏。水电改造是一个系统工程，网友在实施过程中需要把水电有关设计、施工、验收方面的知识结合起来参考。
              </p>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/news1">
                  <img
                    src="../../assets/images/information/news1.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    住房租赁市场回暖 监管强化助力住房租赁市场规范发展
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news2">
                  <img
                    src="../../assets/images/information/news2.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    六省市整治住房租赁市场 住房租赁立法亟须按下“快进键”
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news3">
                  <img src="../../assets/images/information/new3.jpeg" alt="" />
                  <div class="msg">房屋租赁经纪行业制度</div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news4">
                  <img src="../../assets/images/information/new4.jpeg" alt="" />
                  <div class="msg">中央加快住房租赁市场立法</div>
                </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/help/帮助背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        margin-top: 25px;
        .news_title {
          color: #000;
          overflow: hidden;
        }
        .newsText {
          height: 55px;
          line-height: 55px;
          text-align: center;
          font-size: 20px;
          background-color: #f1f3f6;
        }
        .news_content {
          text-align: left;
          color: #000;
          .bold {
            font-weight: 700;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: rgb(51, 51, 51);
            text-align: justify;
            line-height: 24px;
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
